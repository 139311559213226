import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import queryString from "query-string";
import SEO from "../../components/SEO";
import IntroHeader from "../../components/IntroHeader";
import { Container, Section } from "../../components/LayoutUtils";
import Layout from "../../layouts/index";
import getPageImage from "../../utils/getPageImage";
import GalleryItemList from "../../components/GalleryItemList";
import styles from "./style.module.scss";

const GalleryPage = ({ data, location: { search = "" } }) => {
  const page = data.contentfulPage;
  const { tag } = queryString.parse(search);
  return (
    <Layout>
      <SEO
        image={getPageImage(page)}
        title="Gallery"
        description="Pictures of our favourite handmade suits, coats and jackets"
      />
      <IntroHeader {...page} />
      <Container>
        <Section>
          <div className="row">
            {tag ? (
              <Link className={styles.tag} to="/gallery">
                {tag}
              </Link>
            ) : null}
          </div>
          <GalleryItemList filter={tag} />
        </Section>
      </Container>
    </Layout>
  );
};

GalleryPage.propTypes = {
  location: PropTypes.shape().isRequired,
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "gallery" }) {
      ...page
    }
  }
`;

export default GalleryPage;
